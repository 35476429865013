import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledStatCard = styled.article`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 320px;
  min-height: 130px;
  padding: 25px;
  background-image: linear-gradient(90deg, #a051fe 15%, #c595ff 87%);
  box-shadow: 0 2px 6px 0 #000000;
  border-radius: 15px;
  color: rgb(${props => props.theme.white});
  @media screen and (min-width: ${props => props.theme.breakpointMd}) {
    max-width: 330px;
  }
`

const StatNumber = styled.div`
  font-family: ${props => props.theme.atramentSemiBoldItalic};
  margin-right: 15px;
  text-align: center;

  .stat-number__value {
    font-size: 40px;
  }
`

const StatNumberPercentage = styled(StatNumber)`
  display: flex;
  width: 72px;
  height: 72px;
  justify-content: center;
  align-items: center;
  border: solid 2px rgba(${props => props.theme.white});
  border-radius: 50%;
  flex-shrink: 0;
  .stat-number__percentage {
    font-size: 17px;
  }
`

const StatDetails = styled.span`
  line-height: 20px;
  text-align: left;
  font-size: 14px;
`

const StatCard = props => (
  <StyledStatCard>
    {props.isPercentage ? (
      <StatNumberPercentage>
        <span className="stat-number__value">
          {props.statNumber.toLocaleString('en-GB')}
          <span className="stat-number__percentage">%</span>
        </span>
      </StatNumberPercentage>
    ) : (
      <StatNumber>
        <span className="stat-number__value">
          {props.statNumber.toLocaleString('en-GB')}
        </span>
      </StatNumber>
    )}
    <StatDetails>{props.statText}</StatDetails>
  </StyledStatCard>
)

StatCard.propTypes = {
  statText: PropTypes.string,
  statNumber: PropTypes.number,
}

export default StatCard
