import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Img from 'react-webp-image'

const StyledQuestionWrapper = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  background: rgb(${props => props.theme.white});
  padding: 12px 30px 50px;
  margin-bottom: 50px;
`
const StyledAvatar = styled.div`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
  flex-shrink: 0;
  img {
    object-fit: cover;
    font-family: 'object-fit: cover';
    width: 100%;
    height: 100%;
  }
  @media screen and (min-width: ${props => props.theme.breakpointMd}) {
    width: 52px;
    height: 52px;
  }
`

const StyledQuestion = styled.h3`
  font-size: 21px;
  font-family: ${props => props.theme.atramentRegular};
  font-weight: ${props => props.theme.regular};
  text-transform: uppercase;
  color: rgb(${props => props.theme.navy});
  letter-spacing: 1.31px;
  line-height: 27px;
  margin: 0;
  text-align: left;
`

const Question = props => (
  <StyledQuestionWrapper>
    <StyledAvatar>
      <Img
        webp={`video-avatars/${props.imageAvatar}.webp`}
        src={`video-avatars/${props.imageAvatar}.png`}
        alt={props.imageAlt}
      />
    </StyledAvatar>
    <StyledQuestion>{props.questionText}</StyledQuestion>
  </StyledQuestionWrapper>
)

Question.propTypes = {
  questionText: PropTypes.string,
}

export default Question
