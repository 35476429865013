import React from 'react'
import PropTypes from 'prop-types'
import theme from '../util/theme'

import styled from 'styled-components'
import AnimateHeight from 'react-animate-height'

import Question from '../components/question'
import AnswerButton from '../components/answerButton'
import Video from '../components/video'
import ResultButton from '../components/resultButton'

import HalfTone from '../images/half-tone.svg'

const StyledQuestionBlock = styled.article`
  display: flex;
  flex-direction: column;
  width: 100%;
  &:after {
    content: '';
    height: 40px;
    width: 100%;
    position: relative;
    bottom: 0;
    background: url(${HalfTone});
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 0;
  }
  @media screen and (max-width: ${props => props.theme.breakpointMdMax}) {
    margin-bottom: 40px;
    :last-of-type {
      margin-bottom: 0;
    }
  }
  @media screen and (-ms-high-contrast: none) and (min-width: ${props =>
      props.theme.breakpointMdMax}) {
    width: calc(50% - 15px);
  }
`

const AnswerWrapper = styled.div`
  position: absolute;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  width: 142px;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
  @media screen and (min-width: ${props => props.theme.breakpointMd}) {
    width: 170px;
  }
`
const StyledQuestionWrapper = styled.div`
  position: relative;
`

const StyledResults = styled(AnimateHeight)`
  position: relative;
  z-index: 1;
  will-change: height;
  background: white;
  margin-top: -50px;
  div {
    margin: 50px 0 25px;
  }
`

const StyledResultHeader = styled.h4`
  font-size: 21px;
  font-family: ${props => props.theme.atramentRegular};
  font-weight: ${props => props.theme.regular};
  text-transform: uppercase;
  color: rgb(${props => props.theme.navy});
  letter-spacing: 1.31px;
  line-height: 27px;
  margin: 0 0 10px;
  text-align: center;
`

class QuestionBlock extends React.Component {
  questionId = this.props.questionId
  API = `https://www.electricalsafetyfirst.org.uk/api/97seconds/question/${
    this.questionId
  }`
  state = {
    answered: false,
    fetchVideo: false,
    videoIsPlaying: false,
    videoIsFinished: false,
    yes: 0,
    no: 0,
    answer: '',
    revealInfo: false,
    correctAnswer: this.props.correctAnswer,
    isMobile: null,
  }
  componentDidMount() {
    this.setState({
      isMobile: this.checkIfMobile(),
    })
  }
  checkIfMobile = () => {
    if (typeof window !== `undefined`) {
      return (
        !!navigator.platform &&
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      )
    }
  }
  playVideo = () => {
    this.setState({
      videoIsPlaying: true,
    })
  }
  postAnswer = answer => {
    const answerFormatted = answer.toLowerCase()
    let postReq = {
      yes: false,
      no: false,
    }
    postReq[answerFormatted] = true
    fetch(this.API, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(postReq),
    })
      .then(response => {
        return response.json()
      })
      .then(data => {
        this.setPercentage(data)
      })
      .catch(error => {
        console.log(error)
      })
  }
  setPercentage = data => {
    const { yes, no } = data
    this.setState({
      yes,
      no,
    })
  }
  getAnswer = answer => {
    this.setState(
      {
        answered: true,
        answer,
        fetchVideo: true,
      },
      () => {
        this.postAnswer(answer)
      }
    )
  }
  render() {
    return (
      <StyledQuestionBlock
        questionId={this.questionId}
        revealInfo={this.state.revealInfo}
      >
        <Video
          videoId={this.props.videoId}
          videoIsPlaying={this.state.videoIsPlaying}
          imageAlt={this.props.imageAlt}
          fetchVideo={this.state.fetchVideo}
          videoIsFinished={() =>
            this.setState({
              videoIsFinished: true,
            })
          }
          isMobile={this.state.isMobile}
          revealAnswerPlaybackTime={this.props.revealAnswerPlaybackTime}
          imagePoster={this.props.imagePoster}
          overlayQuestion={this.props.overlayQuestion}
        />
        <StyledQuestionWrapper>
          <Question
            videoId={this.props.videoId}
            questionText={this.props.questionText}
            imageAlt={this.props.imageAlt}
            imageAvatar={this.props.imageAvatar}
          />
          <AnswerWrapper>
            {this.state.videoIsFinished ? (
              <ResultButton
                answerIsCorrect={this.state.correctAnswer === this.state.answer}
              />
            ) : (
              <React.Fragment>
                <AnswerButton
                  answerText={'Yes'}
                  colour={theme.darkPurple}
                  getAnswer={this.getAnswer}
                  countTo={this.state.yes}
                  answered={this.state.answered}
                  revealInfo={() => {
                    this.setState({
                      revealInfo: true,
                    })
                  }}
                />
                <AnswerButton
                  answerText={'No'}
                  colour={theme.lightPurple}
                  getAnswer={this.getAnswer}
                  countTo={this.state.no}
                  answered={this.state.answered}
                  revealInfo={() => {
                    this.setState({
                      revealInfo: true,
                    })
                  }}
                />
              </React.Fragment>
            )}
          </AnswerWrapper>
        </StyledQuestionWrapper>
        <StyledResults
          duration={300}
          easing={'ease'}
          height={!this.state.revealInfo ? 0 : 'auto'}
          onAnimationEnd={this.playVideo}
        >
          <div>
            {this.state.videoIsFinished ? (
              <React.Fragment>
                <StyledResultHeader>
                  {this.state.correctAnswer === this.state.answer
                    ? 'Well Done!'
                    : 'Bad luck'}
                </StyledResultHeader>
                <p>Watch another challenge</p>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <StyledResultHeader>
                  {this.state.answer === 'Yes' ? this.state.yes : this.state.no}
                  % of people think so too…
                </StyledResultHeader>
                <p>
                  {this.state.isMobile
                    ? "Watch the video to see if you're right "
                    : 'Keep watching to see if you’re right.'}
                </p>
              </React.Fragment>
            )}
          </div>
        </StyledResults>
      </StyledQuestionBlock>
    )
  }
}

QuestionBlock.propTypes = {
  videoId: PropTypes.string,
  answerText: PropTypes.string,
}

export default QuestionBlock
