import React from 'react'
import PropTypes from 'prop-types'
import YouTubePlayer from 'react-player/lib/players/YouTube'
import Img from 'react-webp-image'

import styled, { keyframes } from 'styled-components'

const videoDisabled = {
  pointerEvents: 'none',
}

const fadeOut = keyframes`
  from {
    opacity: 1;
    /* filter: blur(3px); */
  }

  to {
    opacity: 0;
    /* filter: blur(0); */
    transform: scale(1);
  }
`

const StyledVideoWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`

const StyledVideoPlaceholderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(${props => props.theme.darkPurple});
  animation-name: ${props => (props.fadeElement ? fadeOut : null)};
  animation-duration: 0.6s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    font-family: ${props => props.theme.atramentItalic};
    font-weight: ${props => props.theme.regular};
    text-transform: uppercase;
    color: rgb(${props => props.theme.white});
    letter-spacing: 1.31px;
    line-height: 40px;
    margin: 0;
    text-align: center;
    z-index: 1;
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    span {
      display: block;
    }
  }
`

const StyledVideoPlaceholder = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  font-family: 'object-fit: cover';
  width: 100%;
  height: 100%;
  opacity: .1;
  pointer-events: none;
  transform: scale(1.12);
`

const StyledVideo = styled(YouTubePlayer)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${props => (props.setZIndex ? 1 : 'auto')};
`

class Video extends React.Component {
  state = {
    fadePlaceholder: false,
    setZIndex: false,
    videoDuration: 0,
  }
  fadePlaceholder = () => {
    this.setState({
      fadePlaceholder: true,
    }, () => {
      setTimeout(() => {
        this.setState({
          setZIndex: true
        })
      }, 1300);
    })
  }
  checkVideoFinished = currentPlayback => {
    const currentPlaybackTime = Math.round(currentPlayback)
    // const videoDuration = this.state.videoDuration
    // const percentage = Math.round((100 * currentPlaybackTime) / videoDuration)
    if (currentPlaybackTime >= this.props.revealAnswerPlaybackTime) {
      this.props.videoIsFinished()
    }
  }
  render() {
    const opts = {
      autoplay: 0,
      controls: 1,
      modestbranding: 1,
      muted: 1,
    }
    return (
      <StyledVideoWrapper>
        {this.props.fetchVideo ? (
          <StyledVideo
            url={`https://www.youtube.com/watch?v=${this.props.videoId}`}
            config={{ youtube: { playerVars: opts } }}
            playing={this.props.videoIsPlaying && !this.props.isMobile}
            width="100%"
            height="100%"
            style={
              this.props.videoIsPlaying || this.props.isMobile
                ? null
                : videoDisabled
            }
            onReady={this.fadePlaceholder}
            onProgress={e => this.checkVideoFinished(e.playedSeconds)}
            onDuration={e =>
              this.setState({
                videoDuration: e,
              })
            }
            setZIndex={this.state.setZIndex}
          />
        ) : null}
        <StyledVideoPlaceholderWrapper
          fadeElement={this.state.fadePlaceholder}
        >
          <h3>
            <span>What’s faster?</span> {this.props.overlayQuestion}
          </h3>
          <StyledVideoPlaceholder
            webp={`video-posters/${this.props.imagePoster}.webp`}
            src={`video-posters/${this.props.imagePoster}.png`}
            alt={this.props.imageAlt}
          />
        </StyledVideoPlaceholderWrapper>
      </StyledVideoWrapper>
    )
  }
}

Video.propTypes = {
  videoId: PropTypes.string,
}

export default Video
