import React from 'react'
import styled from 'styled-components'

import Cross from '../images/cross.svg'
import Tick from '../images/tick.svg'

const StyledResultButton = styled.button`
  /* reset button styles */
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;

  /* custom button styles */
  display: block;
  width: 56px;
  height: 56px;
  font-size: 21px;
  font-family: ${props => props.theme.atramentRegular};
  letter-spacing: 1px;
  background: rgb(${props => props.theme.darkPurple});
  box-shadow: 0 2px 4px 0 rgba(${props => props.theme.lightPurple}, 0.5);
  border-radius: 50%;
  padding: 4px;
  margin-left: auto;
  margin-right: auto;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(${props => props.theme.white});
    width: 100%;
    height: 100%;
    border-radius: 100%;
    text-transform: uppercase;
    color: rgb(${props => props.theme.white});
    pointer-events: none;
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      background: url(${props => (props.answerIsCorrect ? Tick : Cross)});
      background-position: center;
      background-repeat: no-repeat;
    }
  }
`

const ResultButton = props => {
  return (
    <StyledResultButton answerIsCorrect={props.answerIsCorrect} disabled={true}>
      <span />
    </StyledResultButton>
  )
}

export default ResultButton
