import React from 'react'
import theme from '../util/theme'

import Layout from '../components/layout'
import SEO from '../components/seo'
import AnswerButton from '../components/answerButton'
import CTALink from '../components/ctaLink'
import StatCard from '../components/statCard'
import QuestionBlock from '../components/questionBlock'

const Styleguide = () => (
  <Layout>
    <SEO title="Styleguide" />
    <div id="styleguide">
      <div>
        <section>
          <h3>Typography</h3>
          <h1>Safer. Sounder. Register.</h1>
          <h2>What am I registering for?</h2>
          <p>
            Thinking you’ll register it later? Here are some good reasons to do
            it now. In 2017/18…
          </p>
        </section>
        <section>
          <h3>Answer buttons</h3>
          <AnswerButton answerText={'Yes'} colour={theme.darkPurple} />
          <AnswerButton answerText={'No'} colour={theme.lightPurple} />
        </section>
        <section>
          <h3>Call to action buttons</h3>
          <CTALink linkText={'Register now'} />
        </section>
        <section>
          <h3>Stat card</h3>
          <StatCard
            statNumber={61}
            statText={
              'of all accidental domestic fires in England were electrical'
            }
          />
        </section>
        <section>
          <h3>Question Block</h3>
          <QuestionBlock
            questionId={'1'}
            questionText={'Can John eat 10 hot wings in 97 seconds?'}
            videoId={'TTAU7lLDZYU'}
          />
        </section>
      </div>
    </div>
  </Layout>
)

export default Styleguide
